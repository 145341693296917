import React from 'react'
import logo from "../assets/images/logo.jpg"
export default function AppBar() {
    return (

        <div className="flex flex-row justify-between w-full px-5" style={{ maxWidth: 1280, marginTop: 10, marginBottom: 10 }}>
            <div className="flex flex-row w-80 justify-between pt-3 basis-1/4">
                {/* <p className="text-xs "><a href="/">Search</a></p> */}
                <p className="text-sm"><a href="#">For Businesses</a></p>

            </div>
            <div className="flex grow justify-center basis-1/2">
                <img src={logo} width={150} height={150} alt="logo" />
            </div>

            <div className="basis-1/4 flex flex-row justify-end pt-3">
                <p className="text-sm"><a href="#about">About Us</a></p>
            </div>
        </div>

    )
}
