import React, { useState } from 'react'
import AppBar from '../components/Appbar'
import Alert from '@mui/joy/Alert';
import CircularProgress from '@mui/joy/CircularProgress';

import { createTheme } from '@mui/joy/styles';





export default function Home() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [success, setSuccess] = useState(false)
    const handleSubmit = async () => {
        if (email === "") {
            setError(true)
        } else {
            setDisabled(true)
            const body = new URLSearchParams({ email: email }).toString();
            await fetch("https://script.google.com/macros/s/AKfycbxAd_tkODeZEnIV3lPV9099ycfkXdfl6s2_dHkBH4cO0Ze04vX0oyNhrQO3RqVI62gj/exec", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: body
            }).then((res) => {
                setSuccess(true)
                setDisabled(false)
                setTimeout(() => {
                    setSuccess(false)

                }, 3000)
            }).catch((e) => {

            })
        }
    }
    return (
        <div className="flex justify-center w-full">
            <div style={{
                maxWidth: 1280,

                display: 'flex',
                justifySelf: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <AppBar />

                <div >
                    <div className=" w-full text-center mt-28">
                        <p className=" text-6xl font-bold" >Currently in Development</p>
                        <p className="text-lg font-normal mt-5">Enter your email below to get notified when we offically launch</p>
                        <div className="mt-5 flex sm:flex-row flex-col items-center px-5">
                            <input value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} placeholder='Email' type='email' className=' w-full border border-gray-300 p-2 sm:w-9/12 mx-4 mb-3 sm:mb-0' />

                            <button disabled={disabled} onClick={handleSubmit} style={{ backgroundColor: "#FC6161" }} className=' w-2/4 sm:py-2 py-2 sm:w-32 px-4 text-white font-medium rounded'>{disabled ? <CircularProgress thickness={1} size='sm' style={{ color: "white" }} variant="plain" /> : "Get Notified"}</button>
                        </div>
                        {error ? <p className='text-start text-xs text-red-600'>Email required</p> : <></>}
                    </div>
                </div>
                {success ? <Alert className="mt-5" color='success'>Email was successfully sent!</Alert> : <></>}

                <div id="about" className=" mt-52 w-screen flex justify-center items-center flex-col" style={{ backgroundColor: '#272635', paddingTop: 15, color: 'white' }}>
                    <div style={{ maxWidth: 1280, justifyContent: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center', width: '40%' }}>
                        <p className=" font-semibold text-base text-white mb-5 mt-20">Learn More</p>
                        <p className=" text-5xl  font-bold mb-10 leading-snug">About Us</p>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center justify-between text-center mb-20" style={{ maxWidth: 1280 }}>
                        <div className="w-1/2">
                            <img src="https://media.istockphoto.com/id/1338095424/photo/portrait-of-three-smiling-women.jpg?s=612x612&w=0&k=20&c=6vjpUGhh_aeAPf4Ve1Jsxvk2v0GESujKYy_jUxUyiVg=" />
                        </div>
                        <div className="sm:w-1/2 text-wrap w-full mt-10 sm:mt-0 px-5 text-center sm:text-left">
                            <p className="mb-5">Welcome to Afro Allure! Our journey began in university when we struggled to find black stylists to cater to our hair needs. After eventually finding some talented students, we realized how difficult the discovery process was. This experience inspired us to create Afro Allure, aiming to make it convenient for black individuals in predominantly white areas to find skilled black stylists.</p>
                            <p className="mb-5">Afro Allure is dedicated to supporting and growing black beauty businesses, whether they're just starting out or already well-established. Our platform connects clients with a growing network of black beauty professionals and offers an array of tools and features designed to enhance business operations.</p>

                            <p>At Afro Allure, we believe it's our duty to support the businesses that trust our platform. We are committed to providing the best tools and services to help your beauty business thrive. Join us and experience the convenience and benefits of Afro Allure today!</p>
                        </div>
                    </div>
                </div>
                <div className="  w-screen flex justify-center items-center flex-col mb-80" style={{ backgroundColor: 'white', paddingTop: 15, color: "#272635" }}>
                    <div style={{ width: 1280, justifyContent: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center', width: '40%' }}>
                        <p className=" font-semibold text-base  mb-5 mt-28">Discover</p>
                        <p className=" text-5xl text-center text-wrap  font-bold mb-5 leading-snug">Find and Book Beauty Professionals Near You</p>
                        <p className="text-lg  font-normal mb-20">With Afro Allure, it's easy to find and book appointments with black beauty professionals in your area. Simply browse through our curated list of talented professionals, read reviews, and book your next appointment hassle-free.</p>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center justify-between text-center mb-20" style={{ width: 1280 }}>
                        <div className="flex flex-col mb-10 sm:mb-0 w-1/4 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mb-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>

                            <p className="text-3xl font-semibold mb-5">Browse Our Selection of Beauty Professionals</p>
                            <p className=" text-base font-normal">Explore a diverse range of black beauty professionals offering services tailored to your needs.</p>
                        </div>
                        <div className="flex flex-col mb-10 sm:mb-0 w-1/4 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mb-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>

                            <p className="text-3xl font-semibold mb-5">Read Reviews and Book with Confidence</p>
                            <p className=" text-base font-normal">Gain insights from genuine customer reviews and make informed decisions before booking your appointment.</p>
                        </div>
                        <div className="flex flex-col mb-10 sm:mb-0 w-1/4 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mb-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                            </svg>

                            <p className="text-3xl font-semibold mb-5">Seamless Booking and Payment Processing</p>
                            <p className=" text-base font-normal">Experience a hassle-free booking process with secure payment options for your convenience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
