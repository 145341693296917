import './App.css';
import Home from './pages/Home';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXZiDAK2x_j1VQXilvz7eAsGouj6KQsJc",
  authDomain: "afroallure-da5d2.firebaseapp.com",
  projectId: "afroallure-da5d2",
  storageBucket: "afroallure-da5d2.appspot.com",
  messagingSenderId: "36014107093",
  appId: "1:36014107093:web:9b6bf93fd6611a22e4355c",
  measurementId: "G-CM5Y7MG702"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className=' overflow-hidden'>
      <Home />
    </div>
  );
}

export default App;
